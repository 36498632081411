// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-acts-tsx": () => import("./../../../src/pages/acts.tsx" /* webpackChunkName: "component---src-pages-acts-tsx" */),
  "component---src-pages-artists-tsx": () => import("./../../../src/pages/artists.tsx" /* webpackChunkName: "component---src-pages-artists-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

